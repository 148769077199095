.bannerWrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.modelWrapper {
    display: flex;
    width: 30%;
    height: 100%;
    margin-bottom: 10%;
}

.descriptionWrapper {
    display: flex;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 3rem;
    font-weight: 500;
    white-space: break-spaces;
    margin-bottom: 17%;
    color: white;
    font-family: Fredoka,sans-serif;
}

.scrollToProjects {
  display: flex;
  align-items: center;
  font-size: 2rem;
  text-align: start;
  align-self: flex-start;
  margin-top: 1rem;
  border-bottom: 3px solid white;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: white;
}
.iconsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    width: 100%;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    margin-right: 5%;
    border-radius: 10px;
    background: rgb(1,3,32);
    background: linear-gradient(225deg, rgba(1,3,32,1) 0%, rgba(45,31,51,1) 20%, rgba(70,96,170,1) 40%, rgba(132,191,198,1) 60%, rgba(255,123,94,1) 80%);
    background-size: 400% 400%;
    animation: backgroundAnim 20s ease infinite reverse;
    border: 1px solid white;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 6px 2px rgba(0,0,0,0.74); 
    box-shadow: 5px 5px 6px 2px rgba(0,0,0,0.74);
    text-transform: none;
    text-decoration: none;
    color: white;
  }
  .icon:hover {
    transform: scale(1.1);
  }
  @keyframes backgroundAnim {
    0% {
      background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@media screen and (min-width: 300px){
  .bannerWrapper{
    flex-direction: column;
    height: fit-content;
  }
  .modelWrapper{
    height: 90vh;
    width: 100%;
    margin-bottom: 0%;
  }
  .descriptionWrapper {
    font-size: 1.5rem;
    width: 90%;
  }
  .icon{
    width: 40px;
    height: 40px;
  }
  .scrollToProjects{
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 700px){
  .descriptionWrapper {
    font-size: 2.5rem;
    width: 90%;
  }
  .icon{
    width: 60px;
    height: 60px;
  }
  .scrollToProjects{
    font-size: 2.3rem;
  }
}


@media screen and (min-width: 900px){
  .bannerWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
  }
  .modelWrapper {
    display: flex;
    width: 40%;
    height: 100%;
    margin-bottom: 10%;
  }
  .descriptionWrapper {
    display: flex;
    width: 50%;
    word-wrap: break-word;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 500;
    white-space: break-spaces;
    margin-bottom: 17%;
    color: white;
    font-family: Fredoka,sans-serif;
  }
}


@media screen and (min-width: 1200px){
  .bannerWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
  }
  .modelWrapper {
    display: flex;
    width: 30%;
    height: 100%;
    margin-bottom: 10%;
  }
  .descriptionWrapper {
    display: flex;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 3rem;
    font-weight: 500;
    white-space: break-spaces;
    margin-bottom: 17%;
    color: white;
    font-family: Fredoka,sans-serif;
  }
}

@media screen and (min-width: 1600px) {
    .descriptionWrapper{
      font-size: 2.7rem;
    }
}